import React from 'react';
import './ProductManagementTable.scss';
const Table = ({ children }) => {
  return <table className="table">{children}</table>;
};

const TableHeader = ({ children }) => {
  return <thead className="table-header">{children}</thead>;
};

const TableBody = ({ children }) => {
  return <tbody className="table-body">{children}</tbody>;
};

const TableRow = ({ children, onClick }) => (
  <tr className="table-row" onClick={onClick}>
    {children}
  </tr>
);

const TableCell = ({ type, children }) => {
  return <td className={`table-cell ${type}`}>{children}</td>;
};

export { Table, TableHeader, TableBody, TableRow, TableCell };
