import React, { useState, useEffect, useRef } from 'react';
import { MoreVertical } from 'lucide-react';
import './Menu.scss';
const Menu = ({ children }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const menuRef = useRef(null);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setIsMenuOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [menuRef]);

    return (
        <div className={`menu ${isMenuOpen ? 'open' : ''}`} ref={menuRef}>
            <div className="menu-button" onClick={toggleMenu} onBlur={toggleMenu}>
                <MoreVertical style={{}} />
            </div>
            {isMenuOpen && (
                <div className="menu-content">
                    <ul className="menu-list">
                        {React.Children.map(children, (child) =>
                            React.cloneElement(child)
                        )}
                    </ul>
                </div>
            )}
        </div>
    );
};

const MenuButton = ({ onClick, children, isSelected }) => {
    return (
        <li className={`menu-item ${isSelected ? 'selected' : ''}`}>
            <button className="menu-link" onClick={onClick}>
                {children}
                <div className={`radio-indicator ${isSelected ? 'selected' : ''}`} />
            </button>
        </li>
    );
};

const MenuSeparator = () => {
    return <div className="menu-separator" />;
};

export { Menu, MenuButton, MenuSeparator };