import { makeAutoObservable, runInAction, toJS } from "mobx";
import $api from '../http';


const PAGE_SIZE = 20;

class ManagementStore {
    products = [];
    pendingUpdates = new Map();
    sortField = '';
    sortOrder = '';
    currentPage = 0;

    constructor() {
        makeAutoObservable(this);
    }

    fetchProducts = async () => {
        const response = await $api.get('/products');
        const data = response.data;
        this.products = data;
    };

    setPendingUpdate = (productId, field, value) => {
        if (!this.pendingUpdates.has(productId)) {
            this.pendingUpdates.set(productId, {});
        }
        const updates = this.pendingUpdates.get(productId);
        updates[field] = value;
        this.pendingUpdates.set(productId, updates);
    };

    savePendingUpdates = async (productId) => {
        const updates = this.pendingUpdates.get(productId);
        if (updates) {
            const response = await $api.put(`/products/${productId}`, {
                body: JSON.stringify(updates),
            });
            if (response.ok) {
                const updatedProduct = await response.data;
                this.products = this.products.map((product) =>
                    product.id === productId ? updatedProduct : product
                );
                this.pendingUpdates.delete(productId);
            }
        }
    };
    patchProduct = async (productId, field, value) => {
        this.updateProductField(productId, field, value);
        try {
            const response = await $api.patch(`/products/${productId}`, {
                [field.charAt(0).toUpperCase() + field.slice(1)]: value
            });

            return response;
        } catch (error) {
            console.error(`Error updating product field: ${error}`);
            throw error;
        }
    };

    updateProductField = (productId, field, value) => {
        try {

            this.products = this.products.map(product =>
                product.id === productId
                    ? { ...product, [field]: value }
                    : product
            );

        } catch (error) {
            console.error(`Error updating product field: ${error}`);
            throw error;
        }
    };

    sortProducts = (field, order) => {
        this.sortField = field;
        this.sortOrder = order;
        this.products = this.products.slice().sort((a, b) => {
            const aValue = a[field];
            const bValue = b[field];

            return order === 'asc'
                ? (aValue > bValue ? 1 : aValue < bValue ? -1 : 0)
                : (aValue < bValue ? 1 : aValue > bValue ? -1 : 0);
        });
    };

    get sortedProducts() {
        return toJS(this.products);
    }

    nextPage = () => {
        this.currentPage += 1;
    };

    prevPage = () => {
        if (this.currentPage > 0) {
            this.currentPage -= 1;
        }
    };

    get paginatedProducts() {
        const start = this.currentPage * PAGE_SIZE;
        const end = start + PAGE_SIZE;
        return this.sortedProducts.slice(start, end);
    }
}

const managementStore = new ManagementStore();
export default managementStore;
