import React, { useState, useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { Table, TableHeader, TableBody, TableRow, TableCell } from './Table';
import Select from '../../Elements/Select/Select';
import Input from '../../Elements/Input/Input';
import TextArea from '../../Elements/TextArea/TextArea';
import Button from '../../Elements/Button/Button';
import { Menu, MenuButton, MenuSeparator } from '../../Elements/Menu/Menu';
import managementStore from '../../../stores/managementStore';
import categoriesStore from '../../../stores/categoriesStore';
import './ProductManagementTable.scss';

const PAGE_SIZE = 20;

const COLUMN_TRANSLATIONS = {
  'id': 'ID',
  'article': 'Артикул',
  'categoryName': 'Категория',
  'name': 'Название',
  'producerName': 'Производитель',
  'amount': 'Количество',
  'price': 'Цена',
  'color': 'Цвет',
  'size': 'Размер',
  'goodCard': 'Карточка товара',
  'description': 'Описание'
};

const ProductManagementTable = observer(() => {
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [categories, setCategories] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [visibleColumns, setVisibleColumns] = useState([
    { id: 0, value: 'article', name: 'Артикул', visible: true },
    { id: 1, value: 'categoryName', name: 'Категория', visible: true },
    { id: 2, value: 'name', name: 'Название', visible: true },
    { id: 3, value: 'producerName', name: 'Производитель', visible: true },
    { id: 4, value: 'amount', name: 'Кол-во', visible: true },
    { id: 5, value: 'price', name: 'Цена', visible: true },
    { id: 6, value: 'color', name: 'Цвет', visible: true },
    { id: 7, value: 'size', name: 'Размер', visible: true },
    { id: 8, value: 'goodCard', name: 'Карточка товара', visible: true },
  ]);

  const tableContainerRef = useRef(null);
  const originalValues = useRef(new Map());


  useEffect(() => {
    const fetchData = async () => {
      await managementStore.fetchProducts();
      const fetchedCategories = await categoriesStore.fetchCategories();
      setCategories(fetchedCategories);
    };
    fetchData();
  }, []);

  const handleProductSelect = (product) => {
    setSelectedProduct(product);
    setCurrentImageIndex(0);
  };

  const handleFieldBlur = async (product, field, value) => {
    const productOriginals = originalValues.current.get(product.id);
    console.log(product, field, value)
    if (productOriginals && productOriginals.has(field)) {
      const original = productOriginals.get(field);

      if (original !== value) {
        try {
          await managementStore.patchProduct(product.id, field, value);

          // Очищаем сохраненное оригинальное значение
          productOriginals.delete(field);

          // Если для продукта больше нет отслеживаемых полей, удаляем его
          if (productOriginals.size === 0) {
            originalValues.current.delete(product.id);
          }
        } catch (error) {
          console.error('Failed to update field', error);
          // Откатываем изменения
          managementStore.updateProductField(product.id, field, original);
        }
      }
    }
  };
  const handleFieldChange = (product, field, value) => {
    try {
      // Если это первое изменение для этого продукта, сохраним оригинальное значение
      if (!originalValues.current.has(product.id)) {
        originalValues.current.set(product.id, new Map());
      }

      const productOriginals = originalValues.current.get(product.id);
      if (!productOriginals.has(field)) {
        productOriginals.set(field, product[field]);
      }

      // Обновляем значение в сторе
      managementStore.updateProductField(product.id, field, value);
    } catch (error) {
      console.error('Failed to update field', error);
    }
  };

  const handleColumnVisibility = (columnValue) => {
    setVisibleColumns(prevColumns =>
      prevColumns.map(col =>
        col.value === columnValue
          ? { ...col, visible: !col.visible }
          : col
      )
    );
  };

  const nextPage = () => managementStore.nextPage();
  const prevPage = () => managementStore.prevPage();

  const nextImage = () => setCurrentImageIndex(prevIndex => (prevIndex + 1) % (selectedProduct?.photoUrls?.length || 0));
  const prevImage = () => setCurrentImageIndex(prevIndex => (prevIndex - 1 + (selectedProduct?.photoUrls?.length || 0)) % (selectedProduct?.photoUrls?.length || 0));

  const closeDetails = () => setSelectedProduct(null);

  const renderField = (product, field) => {
    const value = product[field];
    if (field === 'categoryName') {
      return (
        <Select
          value={value}
          onChange={(e) => handleFieldChange(product, field, e.target.value)}
          options={categories}
          optionLabel="name"
          optionValue="name"
          onBlur={(e) => handleFieldBlur(product, field, e.target.value)}
        />
      );
    } else if (field === 'description') {
      return (
        <TextArea
          value={value}
          onChange={(e) => handleFieldChange(product, field, e.target.value)}
          onBlur={(e) => handleFieldBlur(product, field, e.target.value)}
        />
      );
    } else if (field === 'article' || field === 'id') {
      return (
        <Input
          value={value}
          readOnly={true}
          label={COLUMN_TRANSLATIONS[field]}
        />);
    } else {
      return (
        <Input
          value={value}
          onChange={(newValue) => handleFieldChange(product, field, newValue)}
          onBlur={(e) => handleFieldBlur(product, field, e.target.value)}
          readOnly={false}
          label={COLUMN_TRANSLATIONS[field]}
          validationRegex={field === 'amount' || field === 'price' ? /^\d*\.?\d*$/ : null}
          validationMessage={(field === 'amount' || field === 'price') && 'Please enter a valid number'}
        />
      );
    }
  };

  return (
    <div className='board'>
      {selectedProduct && (
        <div className="details-container">
          <div className="image-block">
            <div className="pm-image-slider">
              <Button type={"btnOne"} onClick={prevImage} disabled={selectedProduct.photoUrls.length <= 1}>←</Button>
              <img src={selectedProduct.photoUrls[currentImageIndex]} alt="Product" />
              <Button type={"btnOne"} onClick={nextImage} disabled={selectedProduct.photoUrls.length <= 1}>→</Button>
            </div>
            <Button type={"btnOne"} className="close-button" onClick={closeDetails}>✖</Button>
          </div>
          <div className="productDetailsPanel">
            {['description'].map((field) => (
              <p key={field}>
                {renderField(selectedProduct, field)}
              </p>
            ))}
          </div>
        </div>
      )}
      <div className="product-management-table">
        <div className={`table-container ${selectedProduct ? 'table-compressed' : ''}`} ref={tableContainerRef}>
          <Table>
            <TableHeader>
              <TableRow>
                <TableCell className="headerColumnsManagerRow">
                  Столбцы
                  <Menu >
                    <div className="column-menu-content">
                      {Object.keys(COLUMN_TRANSLATIONS).map((column) => (
                        <MenuButton
                          key={column}
                          onClick={() => handleColumnVisibility(column)}
                          isSelected={visibleColumns.find(col => col.value === column)?.visible}
                        >
                          {COLUMN_TRANSLATIONS[column]}
                        </MenuButton>
                      ))}
                      <MenuSeparator />
                      <MenuButton onClick={() => setVisibleColumns(
                        visibleColumns.map(col => ({ ...col, visible: true }))
                      )}>
                        Выбрать все
                      </MenuButton>
                      <MenuButton onClick={() => setVisibleColumns(
                        visibleColumns.map(col => ({ ...col, visible: false }))
                      )}>
                        Убрать все
                      </MenuButton>
                    </div>
                  </Menu>
                </TableCell>
              </TableRow>
              <TableRow>
                {visibleColumns
                  .filter(column => column.visible)
                  .sort((a, b) => a.id - b.id)
                  .map((column) => (
                    <TableCell key={column.value} type={column.value}>
                      <div className="column-header">
                        {column.name}
                        <Menu>
                          <MenuButton onClick={() => managementStore.sortProducts(column.value, 'asc')}>
                            По возрастанию
                          </MenuButton>
                          <MenuButton onClick={() => managementStore.sortProducts(column.value, 'desc')}>
                            По убыванию
                          </MenuButton>
                          <MenuSeparator />
                          <MenuButton onClick={() => handleColumnVisibility(column.value)}>
                            Скрыть
                          </MenuButton>
                        </Menu>
                      </div>
                    </TableCell>
                  ))}
              </TableRow>
            </TableHeader>
            <TableBody>
              {managementStore.paginatedProducts.map((product) => (
                <TableRow key={product.id} onClick={() => handleProductSelect(product)}>
                  {visibleColumns
                    .filter(column => column.visible)
                    .sort((a, b) => a.id - b.id)
                    .map((column) => (
                      <TableCell key={column.value}>
                        {renderField(product, column.value)}
                      </TableCell>
                    ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
        <div className="pagination">
          <Button
            type={"btnTwo"}
            onClick={prevPage}
            disabled={managementStore.currentPage === 0}
            className={`pagination-button ${managementStore.currentPage === 0 ? 'disabled' : ''}`}
          >
            Назад
          </Button>
          <div className="pagination-info">
            Страница {managementStore.currentPage + 1} из {Math.ceil(managementStore.sortedProducts.length / PAGE_SIZE)}
          </div>
          <Button
            type={"btnTwo"}
            onClick={nextPage}
            disabled={managementStore.paginatedProducts.length < PAGE_SIZE}
            className={`pagination-button ${managementStore.paginatedProducts.length < PAGE_SIZE ? 'disabled' : ''}`}
          >
            Вперед
          </Button>
        </div>
      </div>
    </div>
  );
});
export default ProductManagementTable;