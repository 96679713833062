import { makeAutoObservable } from "mobx";
import $api from '../http';
import TokenManager from "./TokenManager";
import { authenticateWithInitData, setCredentials } from '../http/telegramAuth';
class UserStore {
  user = null;
  isLoading = true;
  tokenManager = new TokenManager();

  constructor() {
    makeAutoObservable(this);
    this.initialize();
  }

  async initialize() {
    try {
      this.tokenManager.useCloudStorage = window.Telegram && window.Telegram.WebApp && window.Telegram.WebApp.initData !== '';
      await this.checkAuthentication();
    } catch (error) {
      console.error('Ошибка при инициализации приложения', error);
    } finally {
      this.isLoading = false;
    }
  }

  async authenticateWithInitData(login, password) {
    try {
      const response = await authenticateWithInitData({
        InitData: this.tokenManager.isTelegramMiniApp ? window.Telegram.WebApp.initData : null,
        Login: login,
        Password: password
      });
      if (response.success) {
        const user = this.tokenManager.isTelegramMiniApp ? window.Telegram.WebApp.initDataUnsafe?.user : response.id;

        await this.tokenManager.setTokens(response.accessToken, response.refreshToken);

        this.setUser(user);
      } else {
        throw new Error('Ошибка аутентификации');
      }
    } catch (error) {
      console.error('Authentication failed:', error);
      throw error;
    }
  }

  async setCredentials(login, password) {
    try {
      const response = await setCredentials(
        this.tokenManager.isTelegramMiniApp ? window.Telegram.WebApp.initData : null,
        login,
        password
      );
      if (response.success) {
        const user = this.tokenManager.isTelegramMiniApp ? window.Telegram.WebApp.initDataUnsafe?.user : response.id;

        await this.tokenManager.setTokens(response.accessToken, response.refreshToken);

        this.setUser(user);
      } else {
        throw new Error('Ошибка установки учетных данных');
      }
    } catch (error) {
      console.error('Setting credentials failed:', error);
      throw error;
    }
  }
  setUser(user) {
    this.user = user;
  }

  get isAuthenticated() {
    return !!this.user;
  }

  async checkAuthentication() {
    try {
      // Use TokenManager's getTokens method
      const tokens = await this.tokenManager.getTokens();

      if (tokens && tokens.accessToken) {
        await this.authenticateWithInitData(null, null);
      } else {
        this.clearUser();
      }
    } catch (error) {
      console.error('Authentication check failed', error);
      await this.tokenManager.clearTokens();
      this.clearUser();
    }
  }


  clearUser() {
    this.user = null;
  }

  async refreshTokens() {
    try {
      // Retrieve current tokens first
      const currentTokens = await this.tokenManager.getTokens();

      if (!currentTokens || !currentTokens.refreshToken) {
        throw new Error('No refresh token available');
      }

      const response = await $api.post('/user/refresh', {}, {
        headers: {
          'Authorization': `Bearer ${currentTokens.refreshToken}`
        }
      });

      // Use TokenManager's setTokens method
      await this.tokenManager.setTokens(response.data.accessToken, response.data.refreshToken);
    } catch (error) {
      console.error('Token refresh failed', error);
      await this.tokenManager.clearTokens();
      this.clearUser();
    }
  }
}

export default new UserStore();