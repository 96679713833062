import React from 'react';
import './Select.scss';

const Select = ({
  value,
  onChange,
  onBlur,
  options,
  optionLabel,
  optionValue,
  displayValue
}) => {
  const selectedOption = options.find(option => option[optionValue] === value);

  const handleChange = (e) => {
    const selectedOptionValue = e.target.value;
    const selectedOptionObject = options.find(option =>
      String(option[optionValue]) === selectedOptionValue
    );

    // Вызываем onChange с полным объектом опции
    if (onChange) {
      onChange({
        target: {
          value: selectedOptionObject ? selectedOptionObject[optionLabel] : selectedOptionValue
        }
      });
    }
  };

  return (
    <select
      className="select"
      value={value}
      onChange={onChange}
      onBlur={onBlur}
    >
      <option value="">Выберите категорию</option>
      {options.map((option) => (
        <option
          key={option[optionValue]}
          value={option[optionValue]}
        >
          {option[optionLabel]}
        </option>
      ))}
    </select>
  );
};

export default Select;
