import axios from 'axios';
import { api_addr } from './urls';
import userStore from '../stores/userStore';
import TokenManager from '../stores/TokenManager';

const tokenManager = new TokenManager();

const $api = axios.create({
    baseURL: api_addr
});

$api.interceptors.request.use(async (config) => {
    try {
        // Get tokens using TokenManager
        const tokens = await tokenManager.getTokens();

        if (tokens && tokens.accessToken) {
            config.headers.Authorization = `Bearer ${tokens.accessToken}`;
        }

        return config;
    } catch (error) {
        console.error('Error setting authorization header:', error);
        return config;
    }
});

$api.interceptors.response.use(
    response => response,
    async (error) => {
        const originalRequest = error.config;

        // Check if the error is due to an unauthorized access (401)
        if (error.response && error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;

            try {
                // Attempt to refresh tokens
                await userStore.refreshTokens();

                // Retry the original request with new tokens
                return $api(originalRequest);
            } catch (refreshError) {
                console.error('Token refresh failed', refreshError);

                // Clear tokens and user data
                userStore.clearTokens();
                userStore.clearUser();

                // Redirect to login or handle authentication failure
                // You might want to add a method to handle navigation or show a login prompt
                throw refreshError;
            }
        }

        return Promise.reject(error);
    }
);

export default $api;