import $api from '../http';

class TokenManager {
    constructor() {
        this.accessToken = null;
        this.refreshToken = null;
        this.useCloudStorage = false;

        // Check if running in Telegram Mini App
        this.isTelegramMiniApp = window.Telegram && window.Telegram.WebApp.initData !== '';
    }

    // Comprehensive token storage method
    async setTokens(accessToken, refreshToken) {
        this.accessToken = accessToken;
        this.refreshToken = refreshToken;

        // Update API headers
        $api.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;

        // Multiple storage strategies
        try {
            // 1. Telegram CloudStorage (if available)
            if (this.isTelegramMiniApp) {
                await window.Telegram.WebApp.CloudStorage.setItem('accessToken', accessToken);
                await window.Telegram.WebApp.CloudStorage.setItem('refreshToken', refreshToken);
            }

            // 2. IndexedDB (more persistent than localStorage)
            await this.setIndexedDBTokens(accessToken, refreshToken);

            // 3. Fallback to localStorage
            localStorage.setItem('accessToken', accessToken);
            localStorage.setItem('refreshToken', refreshToken);

            // 4. Optional: Cookie storage (if needed)
            this.setTokenCookies(accessToken, refreshToken);
        } catch (error) {
            console.error('Token storage error:', error);
        }
    }

    // IndexedDB storage method
    async setIndexedDBTokens(accessToken, refreshToken) {
        return new Promise((resolve, reject) => {
            const request = indexedDB.open('AuthStorage', 2); // Increment version number

            request.onupgradeneeded = (event) => {
                const db = event.target.result;
                // Explicitly create the object store if it doesn't exist
                if (!db.objectStoreNames.contains('tokens')) {
                    db.createObjectStore('tokens', { keyPath: 'type' });
                }
            };

            request.onsuccess = (event) => {
                const db = event.target.result;

                // Double-check object store exists before transaction
                if (!db.objectStoreNames.contains('tokens')) {
                    reject(new Error('Tokens object store not found'));
                    return;
                }

                const transaction = db.transaction(['tokens'], 'readwrite');
                const store = transaction.objectStore('tokens');

                store.put({ type: 'accessToken', value: accessToken });
                store.put({ type: 'refreshToken', value: refreshToken });

                transaction.oncomplete = () => resolve();
                transaction.onerror = (error) => reject(error);
            };

            request.onerror = (error) => reject(error);
        });
    }

    // Cookie-based token storage (optional)
    setTokenCookies(accessToken, refreshToken) {
        // Set cookies with appropriate security flags
        document.cookie = `accessToken=${accessToken}; path=/; SameSite=Strict; Secure`;
        document.cookie = `refreshToken=${refreshToken}; path=/; SameSite=Strict; Secure`;
    }

    // Comprehensive token retrieval method
    async getTokens() {
        // Check multiple storage locations
        if (this.accessToken) return { accessToken: this.accessToken, refreshToken: this.refreshToken };

        // 1. Telegram CloudStorage
        if (this.isTelegramMiniApp) {
            const accessToken = await window.Telegram.WebApp.CloudStorage.getItem('accessToken');
            const refreshToken = await window.Telegram.WebApp.CloudStorage.getItem('refreshToken');
            if (accessToken && refreshToken) return { accessToken, refreshToken };
        }

        // 2. IndexedDB
        const indexedDBTokens = await this.getIndexedDBTokens();
        if (indexedDBTokens) return indexedDBTokens;

        // 3. Fallback to localStorage
        const localAccessToken = localStorage.getItem('accessToken');
        const localRefreshToken = localStorage.getItem('refreshToken');
        if (localAccessToken && localRefreshToken) {
            return { accessToken: localAccessToken, refreshToken: localRefreshToken };
        }

        // 4. Optional: Cookie retrieval
        const cookieTokens = this.getTokenCookies();
        if (cookieTokens) return cookieTokens;

        return null;
    }

    // IndexedDB token retrieval
    async getIndexedDBTokens() {
        return new Promise((resolve, reject) => {
            const request = indexedDB.open('AuthStorage', 2); // Match version number

            request.onupgradeneeded = (event) => {
                const db = event.target.result;
                if (!db.objectStoreNames.contains('tokens')) {
                    db.createObjectStore('tokens', { keyPath: 'type' });
                }
            };

            request.onsuccess = (event) => {
                const db = event.target.result;

                // Ensure 'tokens' store exists
                if (!db.objectStoreNames.contains('tokens')) {
                    resolve(null);
                    return;
                }

                const transaction = db.transaction(['tokens'], 'readonly');
                const store = transaction.objectStore('tokens');

                const accessTokenRequest = store.get('accessToken');
                const refreshTokenRequest = store.get('refreshToken');

                Promise.all([accessTokenRequest, refreshTokenRequest])
                    .then(([accessTokenResult, refreshTokenResult]) => {
                        if (accessTokenResult && refreshTokenResult) {
                            resolve({
                                accessToken: accessTokenResult.value,
                                refreshToken: refreshTokenResult.value
                            });
                        } else {
                            resolve(null);
                        }
                    })
                    .catch(reject);
            };

            request.onerror = (error) => {
                console.error('IndexedDB open error:', error);
                reject(error);
            };
        });
    }

    // Cookie token retrieval
    getTokenCookies() {
        const cookies = document.cookie.split('; ');
        const accessTokenCookie = cookies.find(row => row.startsWith('accessToken='));
        const refreshTokenCookie = cookies.find(row => row.startsWith('refreshToken='));

        if (accessTokenCookie && refreshTokenCookie) {
            return {
                accessToken: accessTokenCookie.split('=')[1],
                refreshToken: refreshTokenCookie.split('=')[1]
            };
        }

        return null;
    }

    // Clear tokens from all storage methods
    async clearTokens() {
        this.accessToken = null;
        this.refreshToken = null;

        // Remove API authorization
        delete $api.defaults.headers.common['Authorization'];

        try {
            // 1. Telegram CloudStorage
            if (this.isTelegramMiniApp) {
                await window.Telegram.WebApp.CloudStorage.removeItem('accessToken');
                await window.Telegram.WebApp.CloudStorage.removeItem('refreshToken');
            }

            // 2. IndexedDB
            await this.clearIndexedDBTokens();

            // 3. localStorage
            localStorage.removeItem('accessToken');
            localStorage.removeItem('refreshToken');

            // 4. Cookies
            this.clearTokenCookies();
        } catch (error) {
            console.error('Token clearing error:', error);
        }
    }

    // Clear IndexedDB tokens
    async clearIndexedDBTokens() {
        return new Promise((resolve, reject) => {
            const request = indexedDB.open('AuthStorage', 2); // Match version number

            request.onsuccess = (event) => {
                const db = event.target.result;

                // Ensure 'tokens' store exists before transaction
                if (!db.objectStoreNames.contains('tokens')) {
                    resolve();
                    return;
                }

                const transaction = db.transaction(['tokens'], 'readwrite');
                const store = transaction.objectStore('tokens');

                store.delete('accessToken');
                store.delete('refreshToken');

                transaction.oncomplete = () => resolve();
                transaction.onerror = (error) => reject(error);
            };

            request.onerror = (error) => reject(error);
        });
    }

    // Clear token cookies
    clearTokenCookies() {
        // Expire existing cookies
        document.cookie = 'accessToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
        document.cookie = 'refreshToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    }

    // Authentication check method
    async checkAuthentication() {
        const tokens = await this.getTokens();

        if (tokens && tokens.accessToken) {
            try {
                // Your existing authentication verification logic
                await this.authenticateWithInitData(null, null);
            } catch (error) {
                console.error('Token verification failed', error);
                await this.clearTokens();
            }
        } else {
            this.clearUser();
        }
    }

    get isAuthenticated() {
        return !!this.user;
    }
}

export default TokenManager;